.modal {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.45);
    top: 0;
}

.form {
    margin: 5% auto;
    padding: 20px;
    border: 1px black solid;
    height: 300px;
    width: 75%;
    background-color: white;
}

#login {
    display: grid;
}

.main-section {
    grid-column: 1;
}

.right-section {
    grid-column: 2;
}